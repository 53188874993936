import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import { Switch } from 'antd';
import { CheckBox, Grommet } from 'grommet';
import { grometTheme } from './gromet-theme';

// assets - css
import 'antd/dist/antd.css';
import './assets/css/index.css';

// assets - img 
import logoLight from './assets/img/logo-light.png';
import logoDark from './assets/img/logo-dark.png';

export default class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      darkMode: false,
    };
  }

  render() {

    // apply UI theme
    if (this.state.darkMode) {
      document.body.classList.add('theme-dark');
      document.body.classList.remove('theme-light');
    } else {
      document.body.classList.add('theme-light');
      document.body.classList.remove('theme-dark');
    }

    return (
      <Grommet theme={grometTheme}>
        <div className="App">
          <div className="App-header">
            {/* <CheckBox label='Dark Theme' toggle={true} checked={this.state.darkMode} reverse={true}
              onChange={ () => {
                this.setState(
                  { darkMode: !this.state.darkMode }
                )
              }}
            /> */}
            <Switch checkedChildren="Dark Mode" unCheckedChildren="Light Mode" defaultChecked
              onClick={ (checked, event) => {
                this.setState(
                  { darkMode: !checked }
                )
              }}
            />
            <span>&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div className="App-body">
            <img src={ this.state.darkMode ? logoDark: logoLight } className="App-logo" alt="logo" />
            <p>
              Let the web has flavour
            </p>
          </div>
          <div className="App-footer">
            Copyright &#169; 2011-{new Date().getFullYear()} CitrusMedia, s.r.o. | Všetky práva vyhradené
          </div>
        </div>
      </Grommet>
    );
  }
}